import React, { ComponentProps, FunctionComponent, useMemo } from 'react'

export type Props = {
  text: string
  trimMultipleNewLines?: boolean
} & ComponentProps<'span'>

const newLineRegex = /(?:\r)?\n/g
const multipleNewLineRegex = /(?:(?:\r)?\n){3,}/g

/**
 * Component that transforms \n to <br/>
 */
export const WithNewLines: FunctionComponent<Props> = ({ text, trimMultipleNewLines, ...otherProps }) => {
  const parts = useMemo(() => {
    const domparts: React.JSX.Element[] = []
    const ntext = trimMultipleNewLines ? text.replace(multipleNewLineRegex, '\n\n') : text
    const parts = ntext.split(newLineRegex)
    for (let i = 0; i < parts.length; i++) {
      if (i !== 0) {
        domparts.push(<br key={`${i}-br`} />)
      }
      domparts.push(<span key={`${i}-span`}>{parts[i]}</span>)
    }
    return domparts
  }, [text, trimMultipleNewLines])

  return <span {...otherProps}>{parts}</span>
}
