import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { WithPreload } from '@app/utils/componentPreload'
import { createSortHandler } from '@app/utils/createSortHandler'
import { ensureType } from '@app/utils/ensureType'
import moment from '@app/utils/moment'
import { assertApiActionResponse } from '@app/utils/performFetchData'
import { useAppDispatch } from '@app/utils/redux'
import { Raw, urlEscaped } from '@app/utils/urlEscaped'

import { useEvent } from '@app/hooks/useEvent'

import { getInitialPublicParentReviews, getNextPublicParentReviews } from '@app/store/actions/reviews'
import { metaTagsSelector, usersSelector } from '@app/store/selectors/user'
import { StoreState } from '@app/store/store'

import { useLandingGallery } from '@app/routes/Static/components/LandingJuly/LandingGallery'

import { SitterGalleryCard, SitterGalleryCardProps } from './SitterGalleryCard'

import classes from './SitterGallery.module.scss'

export const useSitterGallery: (() => {
  controls: React.ReactNode
  gallery: React.ReactNode
}) &
  WithPreload = () => {
  const { reviews, next, fetchedAll } = useController()

  const handleLoadMore = useEvent(async () => {
    if (fetchedAll) return
    await next()
  })

  const gallery = useLandingGallery({
    items: (() => {
      const items = reviews.map(review => ({
        id: review.id,
        node: <SitterGalleryCard {...review} />,
      }))

      return items
    })(),
    loadMore: fetchedAll ? undefined : handleLoadMore,
    itemClassname: classes.item,
  })

  return {
    controls: gallery.controls,
    gallery: gallery.gallery,
  }
}

useSitterGallery.preload = () => async dispatch => {
  await dispatch(getInitialPublicParentReviews()).then(assertApiActionResponse('Reviews fetch failed'))
}

const listSelector = createSelector([(state: StoreState) => state.public_parent_reviews], reviews =>
  Object.values(reviews).sort(createSortHandler(r => [moment(r.attributes.created_at).unix()]))
)

const anonymousParentsSelector = (state: StoreState) => state.anonymous_parents

const fetchedAllSelector = (state: StoreState) => state.public_parent_reviews_meta.fetched_all

const dataSelector = createSelector([listSelector, anonymousParentsSelector, usersSelector, metaTagsSelector], (list, parents, sitters, userMeta) => {
  return list.map(r => {
    const parentId = r.relationships.parent.data!.id
    const sitterId = r.relationships.sitter.data!.id
    const parent = parents[parentId]
    const sitter = sitters[sitterId]
    const meta = userMeta[sitterId]
    const reviews_count = sitter.negative_reviews_count + sitter.positive_reviews_count
    if (sitter.account_type !== 'sitter') throw new Error('Unexpected account type')

    return ensureType<SitterGalleryCardProps & { id: string }>({
      id: r.id,
      text: r.attributes.body,
      date: r.attributes.created_at,
      parent_name: parent.attributes.name,
      name: sitter.name,
      url: meta ? urlEscaped`/${sitter.token}/${new Raw(meta.attributes.hurl_suffix)}` : urlEscaped`/${sitter.token}`,
      avatar_url: sitter.avatar_url ?? undefined,
      age: sitter.age,
      rating: Math.round((sitter.positive_reviews_count / reviews_count) * 5 * 100) / 100,
      rating_count: reviews_count,
    })
  })
})

const useController = () => {
  const dispatch = useAppDispatch()
  return {
    reviews: useSelector(dataSelector),
    next: useCallback(async () => {
      await dispatch(getNextPublicParentReviews())
    }, [dispatch]),
    fetchedAll: useSelector(fetchedAllSelector),
  }
}
