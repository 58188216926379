import React, { FunctionComponent } from 'react'
import isNil from 'lodash/isNil'
import { defineMessages, useIntl } from 'react-intl'

import { MISC_MESSAGES } from '@app/constants/Messages/misc'

import moment from '@app/utils/moment'
import { Link } from '@app/utils/routing/Link'

import { InlineIcon } from '@app/components/Icon/Icon'
import { Expander, Spacer } from '@app/components/Layout/Spacer'
import { LazyLoadedBackgroundImage } from '@app/components/LazyLoadedImage/LazyLoadedBackgoundImage'
import { Typography } from '@app/components/Typography/Typography'
import { WithNewLines } from '@app/components/WithNewLines/WithNewLines'

import typo from '../LandingJuly/Typography.module.scss'
import classes from './SitterGalleryCard.module.scss'

export type SitterGalleryCardProps = {
  url: string
  avatar_url?: string
  name: string
  age: number | null
  rating: number
  rating_count: number
  text: string
  parent_name: string
  date: string
}

export const SitterGalleryCard: FunctionComponent<SitterGalleryCardProps> = ({ url, avatar_url, name, age, date, parent_name, rating, rating_count, text }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <LazyLoadedBackgroundImage className={classes.avatar} src={avatar_url} />

        <div className={classes.info}>
          <div className={cn(typo.landing_h3, classes.name)}>{name}</div>

          {!isNil(age) && <div className={cn(typo.subtext, Typography.Dimmed)}>{formatMessage(MISC_MESSAGES.age, { age })}</div>}
          <div className={cn(typo.subtext, Typography.Dimmed)}>
            <img alt="" src={require('../../assets/rating-star-small.svg?force_file')} /> {rating} ({rating_count})
          </div>
        </div>
      </div>

      <Spacer height="1unit" />

      <div className={typo.subtext}>
        <WithNewLines text={text} />
      </div>

      <Spacer height="1unit" />

      <div className={cn(Typography.DecorateText1, Typography.Dimmed)}>
        {parent_name}, {moment(date, 'YYYY-MM-DD').format('D MMMM YYYY')}
      </div>

      <Spacer height="2unit" />
      <Expander />

      <span className={cn(Typography.DecorateText1, classes.learn_more)}>
        {formatMessage(messages.learn_more)} <InlineIcon icon="chevron-right-small" />
      </span>

      <Link className={classes.overlay} eventName="sitter_preview.profile_link" target="_blank" to={url} />
    </div>
  )
}

const messages = defineMessages({
  learn_more: 'Узнать больше',
})
