import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { createSortHandler } from '@app/utils/createSortHandler'
import moment from '@app/utils/moment'

import { StoreState } from '@app/store/store'

const reviewsSelector = (state: StoreState) => state.tbank_reviews.models
export const tbankReviewsListSelector = createSelector([reviewsSelector], models => {
  return Object.values(models).sort(createSortHandler(r => [moment(r.attributes.created_at).unix()]))
})
export const tbankReviewsMetaSelector = (state: StoreState) => state.tbank_reviews.meta
export const tbankReviewsLoadedSelector = (state: StoreState) => {
  const meta = state.tbank_reviews.meta
  return !isNil(meta.current_page) && !isNil(meta.total_pages) && meta.current_page >= meta.total_pages
}
