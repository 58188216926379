import React, { ComponentProps, forwardRef, useEffect, useRef, useState } from 'react'

import { mergeRefs } from '@app/utils/mergeRefs'

import { useInViewOnce } from '@app/hooks/useInViewOnce'

type Props = { src?: string } & ComponentProps<'div'>

/** Version of LazyLoadedIimage that uses css background-image instead of img tag  */
export const LazyLoadedBackgroundImage = forwardRef<HTMLDivElement, Props>(function LazyLoadedBackgroundImage({ src, style, ...divProps }, ref) {
  const elRef = useRef<HTMLDivElement>(null)
  const [noscript, setNoscript] = useState(true)

  const mergedRef = mergeRefs(elRef, ref)

  useEffect(() => {
    setNoscript(false)
  }, [])

  return noscript ? (
    <noscript>
      <div style={{ ...style, backgroundImage: `url(${src})` }} {...divProps} />
    </noscript>
  ) : (
    <LazyLoadedBackgroundImageClient src={src} style={style} {...divProps} ref={mergedRef} />
  )
})

export const LazyLoadedBackgroundImageClient = forwardRef<HTMLDivElement, Props>(function LazyLoadedBackgroundImageClient({ src, style, ...divProps }, ref) {
  const elRef = useRef<HTMLDivElement>(null)
  const [lazySrc, setLazySrc] = useState<string | undefined>(undefined)

  const inView = useInViewOnce(elRef)

  const mergedRef = mergeRefs(elRef, ref)

  useEffect(() => {
    if (inView) setLazySrc(src)
  }, [src, inView])

  return <div style={{ ...style, backgroundImage: lazySrc ? `url(${lazySrc})` : undefined }} {...divProps} ref={mergedRef} />
})
