import { RefObject, useEffect, useState } from 'react'

import { noop } from '@app/utils/noop'

const elToCallbackMap = new WeakMap<Element, (entry: IntersectionObserverEntry) => unknown>()

const observer = IS_BROWSER
  ? new window.IntersectionObserver(entries => {
      entries.forEach(entry => {
        const cb = elToCallbackMap.get(entry.target)
        if (cb) cb(entry)
      })
    })
  : {
      observe: noop,
      unobserve: noop,
    }

/** Changes state when given ref gets into viewport once, then stops observing */
export const useInViewOnce = (ref: RefObject<Element>) => {
  const [inView, setInView] = useState(!IS_BROWSER)

  useEffect(() => {
    if (inView) return

    const cb = (entry: IntersectionObserverEntry) => {
      setInView(entry.isIntersecting)
    }

    const el = ref.current
    if (!el) return

    elToCallbackMap.set(el, cb)
    observer.observe(el)

    return () => {
      elToCallbackMap.delete(el)
      observer.unobserve(el)
    }
  }, [ref, inView])

  return inView
}
