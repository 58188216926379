import { MutableRefObject, RefCallback } from 'react'

type MaybeRef<V> = ((instance: V) => void) | MutableRefObject<V> | null

function setRef<V>(ref: MaybeRef<V>, value: V) {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref !== null) {
    ref.current = value
  }
}

export function mergeRefs<T = any>(...refs: MaybeRef<T | null>[]): RefCallback<T> {
  return value => {
    refs.forEach(ref => setRef(ref, value))
  }
}
